// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPhone, 
    faEnvelope, 
    faMapMarkerAlt, 
    faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { 
    faLinkedin, 
    faTwitter,
    faInstagram,
    faFacebookF    // Add Facebook icon import
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-curve"></div>
            <div className="footer-content">
                {/* Company Info Section */}
                <div className="footer-section">
                    <img 
                        src="/logo/vector/default-monochrome-white.svg" 
                        alt="Mokonisa Coffee" 
                        className="footer-logo" 
                    />
                    <p>Premium coffee exporter from the highlands of Sidama, Ethiopia. Committed to quality and sustainability.</p>
                    <div className="social-links">
                    <a href="https://www.instagram.com/mokonisa_coffee/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/people/Mokonisa-coffee/61571594390253/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
                </div>

                {/* Quick Links Section */}
                <div className="footer-section">
                <h3>Quick Links</h3>
                <ul className="footer-links">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/our-coffee">Our Coffee</Link></li>
                    <li><Link to="/sustainability">Sustainability</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
                </div>

                {/* Contact Info Section */}
                <div className="footer-section">
                <h3>Contact Us</h3>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faPhone} />
                    <span>+251 911 123 456</span>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a 
                        href="mailto:info@mokonisacoffee.com" 
                        className="footer-email"
                    >
                        info@mokonisacoffee.com
                    </a>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span>Mokonisa, Sidama, Ethiopia</span>
                </div>
            </div>

                {/* Map Section */}
                <div className="footer-section">
                <h3>Find Us</h3>
                <div className="map-container">
                <iframe 
                    title="Mokonisa Coffee Location"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1000!2d38.79089047457772!3d6.488721407807231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sus!4v1625641228446!5m2!1sen!2sus"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{ border: 0 }}
                    allowFullScreen=""
                ></iframe>
            </div>
            </div>
            </div>

            <div className="footer-bottom">
                <p>&copy; {currentYear} Mokonisa Coffee. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;