// src/App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LanguageProvider } from './context/LanguageContext';
import Navbar from './components/Navbar/Navbar';
import ComingSoon from './pages/ComingSoon/ComingSoon';

import MainApp from './MainApp';

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

// Loading component
const LoadingSpinner = () => (
  <div className="loading-spinner">Loading...</div>
);

function App() {
  // Ensure boolean conversion and provide fallback
  const isComingSoon = String(process.env.REACT_APP_SHOW_COMING_SOON).toLowerCase() === 'true';

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>
        <LanguageProvider>
          <Router>
            <div className="App">
              {isComingSoon ? (
                <ComingSoon />
              ) : (
                <>
                  <Navbar />
                  <MainApp />
                </>
              )}
            </div>
          </Router>
        </LanguageProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
