// src/pages/Home/Home.js
import React from 'react';
import Header from '../../components/Header/Header';
import AboutSection from '../../components/AboutSection/AboutSection';
import StatsSection from '../../components/StatsSection/StatsSection';
import MarketTicker from '../../components/MarketTicker/MarketTicker';
import Quote from '../../components/Quote/Quote';
import Footer from '../../components/Footer/Footer';

import Testimonial from '../../components/Testimonial/Testimonial';


import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <Header />
      <AboutSection />
      <StatsSection />
      <MarketTicker />
      <Testimonial />
      <Quote />
      <Footer />
    </div>
  );
};

export default Home;