// src/components/StatsSection/StatsSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faIndustry, // For washing stations
    faPeopleGroup, // For member farmers
    faLeaf // For coffee farms
} from '@fortawesome/free-solid-svg-icons';
import './StatsSection.css';

const StatsSection = () => {
    const stats = [
        {
            icon: faIndustry,
            count: '4',
            label: 'Washing Stations',
            description: 'Our processing facilities ensuring premium quality through meticulous washing and sorting processes'
        },
        {
            icon: faPeopleGroup,
            count: '200+',
            label: 'Member Farmers',
            description: 'Supporting local communities while maintaining sustainable farming practices and fair compensation'
        },
        {
            icon: faLeaf,
            count: '3',
            label: 'Coffee Farms',
            description: 'Cultivating exceptional coffee at high altitudes in the rich soils of Sidama Region'
        }
    ];

    return (
        <section className="stats-section">
            <div className="stats-container">
                {stats.map((stat, index) => (
                    <div key={index} className="stat-card">
                        <div className="stat-icon">
                            <FontAwesomeIcon icon={stat.icon} />
                        </div>
                        <div className="stat-content">
                            <h3 className="stat-count">{stat.count}</h3>
                            <h4 className="stat-label">{stat.label}</h4>
                            <p className="stat-description">{stat.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default StatsSection;