// src/components/MarketTicker/MarketTicker.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSeedling,
    faTruck, 
    faHandshake,
    faAward,
    faLeaf,
    faGlobe
} from '@fortawesome/free-solid-svg-icons';
import './MarketTicker.css';

const MarketTicker = () => {
    const promoData = [
        { 
            label: 'Premium Coffee',
            text: 'Grade 1 Sidama Coffee',
            icon: faSeedling
        },
        { 
            label: 'Global Export',
            text: 'Serving International Markets',
            icon: faGlobe
        },
        { 
            label: 'Fair Trade',
            text: 'Supporting Local Farmers',
            icon: faHandshake
        },
        { 
            label: 'ECX Certified',
            text: 'Verified Quality Exporter',
            icon: faAward
        },
        { 
            label: 'Eco-Friendly',
            text: 'Sustainable Practices',
            icon: faLeaf
        },
        { 
            label: 'Fast Shipping',
            text: 'Worldwide Delivery',
            icon: faTruck
        }
    ];

    return (
        <div className="market-ticker">
            <div className="ticker-wrapper">
                <div className="ticker-track">
                    {[...promoData, ...promoData].map((item, index) => (
                        <div key={index} className="ticker-item">
                            <span className="ticker-icon">
                                <FontAwesomeIcon 
                                    icon={item.icon}
                                    className="icon-glow"
                                />
                            </span>
                            <div className="ticker-content">
                                <span className="ticker-label">{item.label}</span>
                                <span className="ticker-text">{item.text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MarketTicker;