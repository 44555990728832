// src/components/Header/Header.js
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [imagesLoaded, setImagesLoaded] = useState({});

    const slides = [
        { 
            url: '/images/bgimage.jpg',
            webp: '/images/bgimage.webp',
            lowRes: '/images/bgimage-low.jpg'
        },
        { 
            url: '/images/sitepic3.JPG',
            webp: '/images/sitepic3.webp',
            lowRes: '/images/sitepic3-low.jpg'
        },
        { 
            url: '/images/IMG_9089.jpg',
            webp: '/images/IMG_9089.webp',
            lowRes: '/images/IMG_9089-low.jpg'
        },
        { 
            url: '/images/bgimage4.jpg',
            webp: '/images/bgimage4.webp',
            lowRes: '/images/bgimage4-low.jpg'
        },
        { 
            url: '/images/bgimage3.jpg',
            webp: '/images/bgimage3.webp',
            lowRes: '/images/bgimage3-low.jpg'
        }
    ];

    useEffect(() => {
        const preloadImages = () => {
            slides.forEach((slide, index) => {
                // Create image objects for all formats
                const mainImg = new Image();
                const webpImg = new Image();
                const lowResImg = new Image();

                // Set onload handlers
                mainImg.onload = () => {
                    setImagesLoaded(prev => ({...prev, [index]: true}));
                };

                // Force high priority loading
                mainImg.fetchPriority = 'high';
                mainImg.loading = 'eager';
                
                // Set sources
                mainImg.src = slide.url;
                if (slide.webp) webpImg.src = slide.webp;
                if (slide.lowRes) lowResImg.src = slide.lowRes;

                // Add preload hints
                const link = document.createElement('link');
                link.rel = 'preload';
                link.as = 'image';
                link.href = slide.url;
                document.head.appendChild(link);
            });
        };

        preloadImages();
        setIsLoading(false);
    }, []);

    const nextSlide = useCallback(() => {
        setCurrentSlide(prev => (prev + 1) % slides.length);
    }, [slides.length]);

    useEffect(() => {
        const timer = setInterval(nextSlide, 5000);
        return () => clearInterval(timer);
    }, [nextSlide]);

    return (
        <header className="header">
            <div className="slideshow">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`slide ${index === currentSlide ? 'active' : ''}`}
                    >
                        {!imagesLoaded[index] && (
                            <img 
                                src={slide.lowRes}
                                alt="Loading..."
                                className="slide-placeholder"
                                loading="eager"
                            />
                        )}
                        <picture>
                            {slide.webp && (
                                <source 
                                    srcSet={slide.webp} 
                                    type="image/webp"
                                />
                            )}
                            <img 
                                src={slide.url}
                                alt="Coffee Farm View"
                                loading="eager"
                                fetchpriority="high"
                                decoding="sync"
                                onLoad={() => {
                                    setImagesLoaded(prev => ({
                                        ...prev, 
                                        [index]: true
                                    }));
                                }}
                                style={{
                                    opacity: imagesLoaded[index] ? 1 : 0,
                                    transition: 'opacity 0.5s ease-in-out'
                                }}
                            />
                        </picture>
                        <div className="slide-overlay"></div>
                    </div>
                ))}
            </div>
            
            <div className="text-container">
                <div className="text-box">
                    <h1>The Finest Sidama Coffee</h1>
                    <Link 
                        to="/about" 
                        className="learn-more-btn"
                        aria-label="Learn more about our coffee"
                    >
                        Learn More
                    </Link>
                </div>
            </div>
            
            {isLoading && (
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            )}
        </header>
    );
};

export default Header;