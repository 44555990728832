// src/components/Quote/Quote.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Quote.css';

const Quote = () => {
    return (
        <section className="quote-section">
            <div className="quote-content">
                <h2>Ready to Experience Premium Sidama Coffee?</h2>
                <p>Get in touch with us for wholesale pricing and shipping information</p>
                <Link to="/contact" className="quote-button"> 
                    Get a Quote
                </Link>
            </div>
        </section>
    );
};

export default Quote;