// src/components/Navbar/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faHome,
    faUsers,
    faMugHot,
    faLeaf,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';


const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 50);
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const toggleMenu = () => setIsOpen(!isOpen);
  
    return (
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
            <div className="navbar-brand">
                <Link to="/" className="logo" onClick={() => setIsOpen(false)}>
                    <img src="/logo/vector/default-monochrome.svg" alt="Mokonisa Coffee Logo" />
                </Link>
                <div className="mobile-controls">
                    <button 
                        className={`hamburger ${isOpen ? 'active' : ''}`}
                        onClick={toggleMenu}
                        aria-label="Toggle menu"
                    >
                        <span className="hamburger-line"></span>
                        <span className="hamburger-line"></span>
                        <span className="hamburger-line"></span>
                    </button>
                </div>
            </div>
            
            <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
            <Link to="/" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faHome} className="nav-icon" />
                Home
            </Link>
            <Link to="/about" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faUsers} className="nav-icon" />
                About Us
            </Link>
           
            <Link to="/contact" className="contact-button">
                Contact Us
            </Link>
        </div>
        </nav>
    );
};

export default Navbar;