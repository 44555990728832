// src/pages/Sustainability/Sustainability.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faLeaf, 
    faDroplet,
    faHandshake,
    faSeedling,
    faTree,
    faPeopleGroup 
} from '@fortawesome/free-solid-svg-icons';
import './Sustainability.css';

const Sustainability = () => {
    const stats = [
        {
            icon: faTree,
            value: "0",
            label: "Deforestation",
            description: "Zero deforestation policy across all operations"
        },
        {
            icon: faDroplet,
            value: "90%",
            label: "Water Saved",
            description: "Reduction in water usage through efficient processing"
        },
        {
            icon: faHandshake,
            value: "100%",
            label: "Fair Trade",
            description: "Commitment to fair trade practices"
        },
        {
            icon: faPeopleGroup,
            value: "500+",
            label: "Farmers",
            description: "Supporting local farming communities"
        }
    ];

    return (
        <div className="sustainability-page">
            <section className="sustainability-hero">
                <div className="hero-content">
                    <h1>Our Commitment to Sustainability</h1>
                    <p>Building a better future for coffee farming and our planet</p>
                </div>
            </section>

            <section className="mission-section">
                <div className="container">
                    <h2>Our Mission</h2>
                    <p className="mission-text">
                        As Ethiopian farmers, we understand firsthand what initiatives are most important to improve the lives of all stakeholders and preserve the health of our land. We strive to be the model for the coffee sector, pushing our team to bring innovative ideas to build a business that will be here for generations to come.
                    </p>
                </div>
            </section>

            <section className="stats-section">
                <div className="container">
                    <h2>Environmental Impact</h2>
                    <div className="stats-grid">
                        {stats.map((stat, index) => (
                            <div className="stat-card" key={index}>
                                <div className="stat-icon">
                                    <FontAwesomeIcon icon={stat.icon} />
                                </div>
                                <h3>{stat.value}</h3>
                                <h4>{stat.label}</h4>
                                <p>{stat.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="practices-section">
                <div className="container">
                    <div className="practices-grid">
                        <div className="practice-content">
                            <h2>Sustainable Practices</h2>
                            <p>Our commitment to sustainability extends through every aspect of our operations, from farming to processing. We implement innovative techniques that preserve our environment while maintaining the highest quality standards.</p>
                        </div>
                        <div className="practice-image">
                            <img src="/images/sustainability1.jpg" alt="Sustainable farming practices" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="community-section">
                <div className="container">
                    <h2>Community Impact</h2>
                    <div className="community-grid">
                        <div className="community-card">
                            <FontAwesomeIcon icon={faHandshake} className="community-icon" />
                            <h3>Fair Partnerships</h3>
                            <p>Supporting local farmers through fair trade practices and sustainable partnerships.</p>
                        </div>
                        <div className="community-card">
                            <FontAwesomeIcon icon={faPeopleGroup} className="community-icon" />
                            <h3>Employment</h3>
                            <p>Creating meaningful employment opportunities in our local communities.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Sustainability;