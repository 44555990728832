// src/components/Testimonial/Testimonial.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import './Testimonial.css';

const Testimonial = () => {
    const testimonials = [
        {
            quote: "The quality and consistency of Mokonisa's coffee is exceptional. Their commitment to sustainable practices and fair partnerships makes them our trusted supplier year after year.",
            author: "Getahun Tadesse",
            position: "Coffee Buyer",
            location: "Addis Abeba, Ethiopia"
        },
        {
            quote: "Working with Mokonisa Coffee has been transformative for our business. Their attention to detail in processing and their dedication to quality is unmatched in the region.",
            author: "Maria Rodriguez",
            position: "Head Buyer",
            location: "Toronto, Canada"
        },
        {
            quote: "The exceptional quality of Sidama coffee from Mokonisa speaks for itself. Their processing methods truly bring out the unique floral and citrus notes that make Ethiopian coffee special.",
            author: "David Cooper",
            position: "Master Roaster",
            location: "Calgary, Canada"
        }
    ];

    return (
        <section className="testimonial-section">
            <div className="testimonial-container">
                <h2 className="section-title">What Our Partners Say</h2>
                <div className="testimonials-grid">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-card">
                            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" />
                            <p className="testimonial-quote">{testimonial.quote}</p>
                            <div className="testimonial-author">
                                <h4 className="author-name">{testimonial.author}</h4>
                                <p className="author-position">{testimonial.position}</p>
                                <p className="author-location">{testimonial.location}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonial;