// src/context/LanguageContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

export const LanguageContext = createContext();

export const translations = {
  en: {
    home: "Home",
    about: "About Us",
    products: "Our Coffee",
    sustainability: "Sustainability",
    export: "Export",
    contact: "Contact",
    comingSoon: "Coming Soon",
    countdown: {
      days: "DAYS",
      hours: "HOURS",
      minutes: "MINUTES",
      seconds: "SECONDS"
    },
    maintenance: "Our Website is under maintenance. We will be back soon.",
    companyName: "Mokonisa Coffees",
    tagline: "Premium Sidama Coffee",
    header: {
        welcome: "Welcome to Mokonisa Coffee",
        subtitle: "The Finest Sidama Coffee",
        slideAlt: "Coffee Farm View"
      },

        about:"About Us",
        aboutText1: "At Mokonisa Coffee, we are dedicated to bringing you the finest Ethiopian coffee, harvested from the lush highlands of Sidama. Our commitment to excellence begins with carefully selected coffee cherries and extends through every step of our artisanal roasting process.",
        aboutText2: "We believe in sustainable practices that honor both our farmers and our environment, ensuring each cup tells a story of tradition, quality, and respect for our Ethiopian heritage.",
        logoAlt: "Mokonisa Coffee Logo",
    
  },
  am: {
    home: "መነሻ",
    about: "ስለ እኛ",
    products: "ቡና ዓይነቶች",
    sustainability: "ዘላቂነት",
    export: "ወደ ውጭ መላክ",
    contact: "አድራሻ",
    comingSoon: "በቅርብ ጊዜ",
    countdown: {
      days: "ቀናት",
      hours: "ሰዓታት",
      minutes: "ደቂቃዎች",
      seconds: "ሰከንዶች"
    },
    maintenance: "ድህረ ገጻችን በእድሳት ላይ ነው። በቅርብ ጊዜ እንመለሳለን።",
    companyName: "ሞኮኒሳ ቡና",
    tagline: "የሲዳማ ጥራት ያለው ቡና",
    header: {
        welcome: "እንኳን ደህና መጡ ወደ ሞኮኒሳ ቡና",
        subtitle: "ምርጥ የሲዳማ ቡና",
        slideAlt: "የቡና እርሻ እይታ"
      },

      about: "ስለ ሞኮኒሳ",
      aboutText1: "ሞኮኒሳ ቡና፣ ከሲዳማ ከፍተኛ ቦታዎች የሚመረተውን የላቀ የኢትዮጵያ ቡና ለእርስዎ ለማቅረብ ቁርጠኞች ነን። የእኛ የጥራት ቁርጠኝነት ከተመረጡ የቡና ፍሬዎች ጀምሮ እስከ የእጅ ጥበብ የቡና ማብሰል ሂደት ድረስ ይዘልቃል።",
      aboutText2: "የአርሶ አደሮቻችንንና አካባቢያችንን የሚያከብሩ ዘላቂ ልምዶችን እናምናለን፣ እያንዳንዱ ኩባያ የባህል፣ የጥራትና የኢትዮጵያ ቅርሳችንን አክብሮት የሚያንጸባርቅ ታሪክ እንዲናገር እናረጋግጣለን።",
      logoAlt: "የሞኮኒሳ ቡና ምልክት"
  }
};

const STORAGE_KEY = 'preferred_language';
const DEFAULT_LANGUAGE = 'en';
const SUPPORTED_LANGUAGES = ['en', 'am'];

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    const browserLang = navigator.language.split('-')[0];
    return SUPPORTED_LANGUAGES.includes(stored) 
      ? stored 
      : SUPPORTED_LANGUAGES.includes(browserLang)
        ? browserLang
        : DEFAULT_LANGUAGE;
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, language);
    document.documentElement.lang = language;
  }, [language]);

  const changeLanguage = (newLang) => {
    if (!SUPPORTED_LANGUAGES.includes(newLang)) {
      console.error(`Language ${newLang} is not supported`);
      return;
    }
    setLanguage(newLang);
  };

  const translate = (key) => {
    const keys = key.split('.');
    let result = translations[language];
    for (const k of keys) {
      result = result?.[k];
    }
    return result ?? key;
  };

  return (
    <LanguageContext.Provider 
      value={{ 
        language, 
        changeLanguage, 
        translate,
        supportedLanguages: SUPPORTED_LANGUAGES 
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};