// src/pages/About/About.js
import React from 'react';
import Footer from '../../components/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSeedling, 
    faIndustry,
    faWater,
    faLeaf,
    faGlobe, 
    faHandshake 
} from '@fortawesome/free-solid-svg-icons';

import './About.css';

const About = () => {
    const founderSection = {
        name: "Tadele Tesema",
        position: "Founder & CEO",
        image: "/images/tade.JPG",
        story: `From humble beginnings in the heart of Sidama, a vision was born in 1996. Our founder, Tadele Tesema, embarked on a journey that would transform not just his life, but the lives of countless farmers and coffee lovers worldwide. Through unwavering dedication and an intimate understanding of Ethiopia's rich coffee heritage, he built Mokonisa Coffee from the ground up.

        Today, his leadership continues to inspire our team, driving us towards excellence in every coffee bean we process. Since launching our export operations in 2022, we have been proudly serving both local and global markets, establishing Mokonisa Coffee as a beacon of quality in the Ethiopian coffee industry. His deep connection to the local community and commitment to sustainable practices ensures we deliver premium coffee while supporting our farming communities.`
    };

    const teamMembers = [
        {
            name: "Wubshet Tadele",
            position: "General Manager & Export Director",
            image: "/images/wube.JPG",
            bio: "A seasoned coffee industry professional with over 8 years of dedicated experience, Wubshet leads our export operations with exceptional expertise. His deep understanding of international coffee markets and strong relationships with global buyers has been instrumental in establishing Mokonisa Coffee's reputation for excellence. Under his leadership, our washing stations maintain the highest quality standards while ensuring sustainable practices and fair partnerships with local farmers."
        }
    ];

    const timelineMilestones = [
        {
            year: "1996",
            title: "Company Established",
            description: "Mokonisa Coffee was founded in Sidama, marking the beginning of our journey in Ethiopian coffee excellence.",
            icon: faSeedling
        },
        {
            year: "1997",
            title: "Mokonisa Washing Station 1",
            description: "Established our first washing station, setting the foundation for premium coffee processing.",
            icon: faIndustry
        },
        {
            year: "2002",
            title: "Mokonisa Washing Station 2",
            description: "Established our second washing station, increasing our premium coffee processing.",
            icon: faIndustry
        },
        {
            year: "2020",
            title: "Bensa Daye Washing Station",
            description: "Expanded operations with our 3rd washing station, increasing our processing capacity.",
            icon: faWater
        },
        {
            year: "2022",
            title: "Chire Expansion",
            description: "Added new washing station and farm in Chire, furthering our commitment to quality coffee production.",
            icon: faLeaf
        }
    ];

    return (
        <div className="about-page">
            {/* Hero Section */}
            <div className="about-hero">
                <div className="hero-content">
                    <h1>About Us</h1>
                    <div className="hero-divider"></div>
                    <p>We pride ourselves on our amazing team, an international group of experts with decades of experience across the coffee value chain.</p>
                </div>
            </div>

            {/* Mission Section */}
            <section className="mission-section">
                <div className="container">
                    <p className="mission-text">
                        Whether it is our farm managers, warehouse workers, or our accountants, <strong>Mokonisa Coffee</strong> employees are dedicated and passionate about the coffee we produce. There is a shared vision and drive to build a company that will be here for generations to come.
                    </p>
                </div>
            </section>

            {/* Founder Section */}
            <section className="founder-section">
                <div className="container">
                    <div className="founder-content">
                        <div className="founder-image">
                            <img src={founderSection.image} alt={founderSection.name} />
                        </div>
                        <div className="founder-text">
                            <h2>{founderSection.name}</h2>
                            <h3>{founderSection.position}</h3>
                            <div className="founder-divider"></div>
                            {founderSection.story.split('\n\n').map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="timeline-section">
                <div className="container">
                    <h2>Our Journey</h2>
                    <div className="timeline">
                        {timelineMilestones.map((milestone, index) => (
                            <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                <div className="timeline-content">
                                    <div className="timeline-icon">
                                        <FontAwesomeIcon icon={milestone.icon} />
                                    </div>
                                    <div className="timeline-year">{milestone.year}</div>
                                    <h3>{milestone.title}</h3>
                                    <p>{milestone.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Team Section */}
            <section className="team-section">
                <div className="container">
                    <h2>Our Leadership</h2>
                    <div className="team-grid">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="team-member">
                                <div className="member-image">
                                    <img src={member.image} alt={member.name} />
                                </div>
                                <div className="member-info">
                                    <h3>{member.name}</h3>
                                    <h4>{member.position}</h4>
                                    <p>{member.bio}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default About;
