// src/components/AboutSection/AboutSection.js
import React from 'react';
import PropTypes from 'prop-types';
import './AboutSection.css';

const AboutSection = () => {
    return (
        <section className="about-section">
            <div className="about-container">
                <div className="about-content">
                    <h2>Hello & Welcome</h2>
                    <div className="text-content">
                        <p className="about-text">
                            At <strong>Mokonisa Coffee</strong>, we are dedicated to bringing you the finest Ethiopian coffee, 
                            harvested from the lush highlands of Sidama Region. Our commitment to excellence begins 
                            with carefully selected coffee cherries and extends through our meticulous 
                            washing and processing methods, ensuring the highest quality beans for export.
                        </p>
                        <p className="about-text">
                            We believe in sustainable practices that honor both our farmers and our environment, 
                            ensuring each bean tells a story of tradition, quality, and respect for our 
                            Ethiopian heritage.
                        </p>
                    </div>
                </div>
                <div className="about-image">
                <img 
                    src="/images/IMG_8445.jpg" 
                    alt="Mokonisa Coffee Processing"
                    className="logo-image"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/images/fallback-image.jpg';
                    }}
                    loading="lazy"
                />
                </div>
            </div>
        </section>
    );
};

AboutSection.propTypes = {
    className: PropTypes.string
};

export default AboutSection;