// src/pages/ComingSoon/ComingSoon.js
import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { translations } from '../../context/LanguageContext';
import './ComingSoon.css';

const ComingSoon = () => {
    const [timeLeft, setTimeLeft] = useState(() => {
      const targetDate = new Date('2024-12-26T00:00:00');
      const now = new Date();
      const difference = targetDate - now;
      
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    });
  
    useEffect(() => {
      const targetDate = new Date('2024-12-26T00:00:00');
  
      const calculateTimeLeft = () => {
        const difference = targetDate - new Date();
        
        if (difference < 0) {
          return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
  
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      };
  
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearInterval(timer);
    }, []);

  return (
    <div className="coming-soon-container">
      <div 
        className="bg-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bgimage3.jpg)`,
        }}
      ></div>
      <div className="overlay"></div>
      
      <main className="content">
        <h1 className="led-text">{t.companyName}</h1>
        <p className="tagline">{t.tagline}</p>

        <div className="countdown-container">
          <div className="countdown-item">
            <span className="number led-text">{String(timeLeft.days).padStart(2, '0')}</span>
            <span className="label">{t.countdown.days}</span>
          </div>
          <div className="countdown-item">
            <span className="number led-text">{String(timeLeft.hours).padStart(2, '0')}</span>
            <span className="label">{t.countdown.hours}</span>
          </div>
          <div className="countdown-item">
            <span className="number led-text">{String(timeLeft.minutes).padStart(2, '0')}</span>
            <span className="label">{t.countdown.minutes}</span>
          </div>
          <div className="countdown-item">
            <span className="number led-text">{String(timeLeft.seconds).padStart(2, '0')}</span>
            <span className="label">{t.countdown.seconds}</span>
          </div>
        </div>
        
        <p className="description">{t.maintenance}</p>
      </main>
    </div>
  );
};

export default ComingSoon;
